import * as React from "react";
import { graphql, HeadProps, PageProps } from "gatsby";

import { IFaqBlock } from "@types";
import { YoastSEOType } from "../../assets/Types";
import FaqsContainer from "../../containers/FaqsContainer/FaqsContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import FAQSchema from "../../components/FAQSchema";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroSingleP: {
        heroSingleLink: string;
        heroSingleLinkText: string;
        heroSingleSubcopy: string;
        heroSingleTitle: string;
      };
      userIntent: Queries.WpFAQTemplate_Userintent;
      faqListP: {
        faqListBlock: IFaqBlock[];
      };
    };
  };
};

const FaqPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { wpPage } = data;
  const {
    template: {
      heroSingleP,
      faqListP: { faqListBlock },
      userIntent,
    },
  } = wpPage;

  useOnUserIntent(userIntent);

  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}

      <FaqsContainer faqList={faqListBlock} heroData={heroSingleP} />
    </LayoutWithLargeFooterCta>
  );
};

export default FaqPage;

type HeadData = {
  wpPage: {
    template: {
      faqListP: Queries.WpFAQTemplate_Faqlistp;
    };
  };
};

export const Head: React.FC<HeadProps<HeadData>> = ({
  data: {
    wpPage: {
      template: { faqListP },
    },
  },
}) => {
  const formattedFaqsQuestions: Queries.WpFaq[] = faqListP.faqListBlock
    ?.map(faq => faq?.faqListQuestions as Queries.WpFaq[])!
    .flat(1)!;

  return <FAQSchema faqsArray={formattedFaqsQuestions} />;
};

export const FAQS_PAGE_QUERY = graphql`
  query FaqsPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      id
      uri
      title
      template {
        ... on WpFAQTemplate {
          ...FaqUserIntentFragment
          templateName
          heroSingleP {
            heroSingleLink
            heroSingleLinkText
            heroSingleSubcopy
            heroSingleTitle
          }
          faqListP {
            faqListBlock {
              faqListBlockItemCtaText
              faqListBlockItemTitle
              faqListQuestions {
                ... on WpFaq {
                  id
                  title
                  faqsC {
                    questionsAnswer
                  }
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`;
