import * as React from "react";
import FaqsCard from "../../components/FaqsCard/FaqsCard";
import { IFaqBlock, IHeroSingle } from "@types";
import styles from "./FaqsContainer.module.scss";

const FaqsContainer: React.FC<{
  faqList: IFaqBlock[];
  heroData: IHeroSingle;
}> = ({ faqList, heroData }) => {
  const FaqsCards = faqList?.map((FaqsCardData, index) => (
    <FaqsCard FaqsCard={FaqsCardData} FaqsCardNumber={index} key={index} />
  ));

  return (
    <>
      <section className="pad-top--large-sans-header pad-bottom--large">
        <div className="full-width shorter">
          <div data-testid="div:faqsTitle" className={styles.faqsTitle}>
            <h1>{heroData?.heroSingleTitle} </h1>
            <p>
              {heroData.heroSingleSubcopy}{" "}
              <a href={heroData.heroSingleLink}>
                {heroData.heroSingleLinkText}
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="full-width narrow content ">
        <div>{FaqsCards} </div>
      </section>
    </>
  );
};

export default FaqsContainer;
