import * as React from "react";
import BlueLinkWithArrow from "../Buttons/BlueLinkWithArrow/BlueLinkWithArrow";
import { IFaqBlock } from "@types";

import styles from "./FaqsCard.module.scss";

const FaqsCard: React.FC<{ FaqsCard: IFaqBlock; FaqsCardNumber: number }> = ({
  FaqsCard,
  FaqsCardNumber,
}) => {
  const { faqListQuestions, faqListBlockItemTitle, faqListBlockItemCtaText } =
    FaqsCard;

  const faqListBlockItem = faqListQuestions.map((faqListBlockItem, index) => {
    return (
      <div className={styles.faq} key={index}>
        <h2>{faqListBlockItem.title}</h2>
        <div>
          <div>
            <div
              className={styles.faqContent}
              dangerouslySetInnerHTML={{
                __html: faqListBlockItem.faqsC.questionsAnswer,
              }}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className={styles.faqsList}>
        <h3
          style={{ marginTop: FaqsCardNumber === 0 ? 0 : "80px" }}
          data-testid="h3:faqListBlockItemTitle"
        >
          {faqListBlockItemTitle}
        </h3>
        {faqListBlockItem}
      </div>
      <div className={styles.backToTop}>
        <p>
          <BlueLinkWithArrow
            arrowDirection={"up"}
            link={"#"}
            linkCopy={faqListBlockItemCtaText}
          />
        </p>
      </div>
    </>
  );
};

export default FaqsCard;
